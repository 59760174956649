import { isFunction } from '@mechhive/helpers';
import clsx from 'clsx';
import type { ButtonProps } from '../Button';
import { ButtonColor, ButtonSize } from '../Button';
import { Icon } from '../ui/Icon';

type ColorClasses = {
  background: string;
  inner: string;
  innerInteraction: string;
}

const colors : Record<ButtonColor, ColorClasses> = {
  'purple': {
    background: 'bg-[#4231AB] group-disabled:bg-[#131836]',   //bg-[linear-gradient(162deg,rgba(255, 255, 255, 0.20)_12.8%,rgba(255,255,255,0.00)_59.71%)]
    inner: 'border-[#A0A4FA] active:border-[#A0A4FA]/60 bg-[radial-gradient(66.67%_66.67%_at_50%_100%,#91A8FC_0%,_#564AD7_100%)] group-disabled:bg-[linear-gradient(180deg,rgba(11,13,40,0.50)_0%,rgba(38,40,64,0.50)_100%)] group-disabled:border-white/10',
    innerInteraction: 'hover:bg-[radial-gradient(66.67%_66.67%_at_50%_100%,_#97A8FF_0%,_#6966FF_100%)] active:bg-[radial-gradient(66.67%_66.67%_at_50%_100%,_#5A7DFB_0%,_#372ABF_100%)]',
  },
  'orange': {
    background: 'bg-[#BA4E00]',
    inner: 'border-[#FB824E] active:border-[#FB824E]/60 bg-[radial-gradient(66.67%_66.67%_at_50%_100%,#F1972D_0%,#E66507_100%)]',
    innerInteraction: 'hover:bg-[radial-gradient(66.67%_66.67%_at_50%_100%,#F7B566_0%,#FF8A00_100%)] active:bg-[radial-gradient(66.67%_66.67%_at_50%_100%,#F18B2D_0%,#FF4D00_100%)]'
  }
}

export const ButtonPrimary = ( { className, children, to, onClick, size = ButtonSize.Default, submit, iconName, target, isLoading, reloadDocument, disabled, color = ButtonColor.Purple, type, name, value, prefetch } : ButtonProps ) => {

  const handleClick = () => {
    if ( isLoading ) {
      return;
    }

    if ( isFunction( onClick ) ) {
      onClick();
    }
  }

  const buttonColor = colors[ color ];

  const Inner = () => {
    return (
      <div className={ `${buttonColor.background} p-[3px] text-white text-base rounded-lg relative` }>
        <div
          className={ clsx(
            `rounded-md border ${buttonColor.inner} flex flex-row items-center gap-4`,
            size === ButtonSize.Small ? 'p-2.5' : 'px-[3px] py-2',
            ( isFunction( onClick ) || ( submit || type === 'submit' ) || to ) && !isLoading && disabled !== true ? buttonColor.innerInteraction : ''
          ) }>
          <div className={ clsx(
            'relative font-semibold font-onest drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)] text-center z-0 flex-auto',
            size === ButtonSize.Small ? 'text-sm leading-[10px]' : 'text-base',
            iconName ? 'pr-8' : ''
          )  }> 
            <div className={ clsx(
              isLoading ? 'opacity-0' : 'opacity-100',
              !isLoading ? 'group-disabled:opacity-50' : ''
            ) }>
              { children }
            </div>
            <div className={ clsx(
              'absolute inset-0 flex items-center justify-center',
              isLoading ? 'opacity-100' : 'opacity-0'
            ) }>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={ 'w-4 h-4' }
                xmlns="http://www.w3.org/2000/svg">
                <g className="spinner_V8m1">
                  <circle
                    cx="12"
                    cy="12"
                    r="9.5"
                    fill="none"
                    className={ 'stroke-white/80' }
                    strokeWidth="3"
                  ></circle>
                </g>
              </svg>
            </div>
          </div>
          { iconName &&
            <div className={ 'absolute right-0 inset-y-0 flex items-center justify-center pr-4 drop-shadow-[0px_1px_1px_rgba(0,0,0,0.25)]' }>
              <Icon
                name={ iconName }
                className={ 'icon-size-6' } />
            </div>
          }
        </div>
      </div>
    )
  }

  // TODO: Revert back when using Attack Challange Mode is disabled
  if ( to ) {
    return (
      <a
        href={ to }
        className={ className }
        target={ target }
        onClick={ onClick ? handleClick : undefined }
        // reloadDocument={ reloadDocument }
        // prefetch={ prefetch }
      >
        <Inner />
      </a>
    )
  }

  return (
    <button
      disabled={ disabled }
      className={ clsx(
        className,
        ( isFunction( onClick ) || ( submit || type === 'submit' ) ) && isLoading !== true && disabled !== true ? 'cursor-pointer' : 'cursor-default disabled:cursor-no-drop',
        'group'
      ) }
      type={ type ? type : ( submit || type === 'submit' ) ? 'submit' : 'button' }
      name={ name }
      value={ value }
      onClick={ onClick ? handleClick : undefined }>
      <Inner />
    </button>
  )
}
